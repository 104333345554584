import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import HttpsRedirect from 'react-https-redirect';

ReactGA.initialize("G-N5YBX9682C") // google property ID
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HttpsRedirect>
      {/*<GoogleReCaptchaProvider
        reCaptchaKey="6LfgoHcoAAAAAGjqOfLKsuA5ON3cCvQpP_UiNx__"
      >*/}
      <App />
      {/*</GoogleReCaptchaProvider>*/}
    </HttpsRedirect>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
