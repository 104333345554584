// Hook imports
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactPlayer from "react-player";

// Component imports
import TrackedButton from '../components/TrackedButton'
import GetInTouch from '../components/GetInTouch.js';

// Style imports
import "./ProductPage.scss"

// Image imports
import backTriangle from '../images/back-triangle.png';
import invisibleHeader from '../images/invisibleHeader.png';
import icon_noiseBlocking from '../images/icon-noiseblocking.png';
import icon_temperature from '../images/icon-temperature.png';
import icon_lightBlocking from '../images/icon-lightblocking.png';
import icon_nyc from '../images/icon-nyc.png';
import icon_linkto from '../images/icon-linkto.png';
import blackout_gif from '../images/blackout-gif-landscape.gif';
import blackout_art_folded from '../images/blackout-art-folded.jpg';
import blackout_art_top from '../images/blackout-art-top.jpg';
import blackout_on_off from '../images/blackout-on-off.mp4';
import invisible_hand2_mobile from '../images/invisible-hand2-mobile.jpg';
import invisible_rails from '../images/invisible-rails.jpg';

// GA Tracking Lib
import { 
    gaButton2ContactForm
} from '../libs/trackingLib';

export default function Blackout(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Product-Page">
      <Helmet>
        <title>Whisper Window Blackout Soundproof Panel</title>
        <meta
          name="description"
          content="
          Whisper Window's no-drill blackout panel blocks out noise
          and light. 10-day lead time in NYC and 100% satisfaction guarantee."
        />
      </Helmet>
      <div className="back" onClick={()=>navigate('/')}>
        <img id="back-triangle" src={backTriangle}/> home
      </div>
      <div className="Product-Header">
        <h1>
          <span id="title-lead">WHISPER DARK</span>
          <br/>Blackout Soundproof Panel
        </h1>
      </div>

      <div className="Product-Highlights">
        <div className="Highlight-Points">
          <div className="highlight-point">
            <img id="icon_lightBlocking" src={icon_lightBlocking}/>
            Full Blackout
          </div>
          <div className="highlight-point">
            <img id="icon_noiseBlocking" src={icon_noiseBlocking}/>
            Noise Reduction
          </div>
          <div className="highlight-point">
            <img id="icon_temperature" src={icon_temperature}/>
            Night-time Insulation
          </div>
          
        </div>
      </div>
      <div>
        <h2 className="Blue-Text">How our Blackout Soundproof Panels Work</h2>
        <br/>
        <div className="Product-Video">

          <iframe 
            src='https://www.youtube.com/embed/njl5wUzzfZw'
            width="100%"
            height="100%"
            frameborder='0'
            allow='autoplay; encrypted-media'
            allowfullscreen
            title='video'
            />
          </div>
          <span 
            className="get-quote-button" 
          >
                <TrackedButton
                  scrollTo={"#get-in-touch"}
                  gaTrack={gaButton2ContactForm}
                  content="Get a quote today!"
                />
          </span>
      </div>


      <div className="Feature-Highlight">
        <br/><br/><br/>
        <h2>Sound & Light Insulation</h2>
        <br/>
        <p className="right-side">
          Effective <b><em>and</em></b> lightweight <br/>
          6-layer panel blocks out 65% of noise, 100% of light
        </p>
        <img id="blackout-gif" src={blackout_gif}/>
        <p className="left-side">
          Perfect for night-time use
        </p>
        <br/><br/><br/>
        <h2>Maximize Style & Beauty</h2>
        <br/>
        <p className="right-side">
          fully customizable to fit every interior<br/>
          with multiple custom art and plain design options
        </p>
        <div className="image-container">
          <img id="blackout_art_folded" src={blackout_art_folded}/>
           <img id="blackout_art_top" src={blackout_art_top}/>
        </div>
        <br/><br/><br/><br/>
      </div>
      <div className="Icon-Highlight">
        <img id="icon_nyc" src={icon_nyc}/>
        <h2>
          10-Days Made-To-Order<br/>
          <span className="title-lead">manufactured in NYC</span>
        </h2>
      </div>
      <br/><br/><br/><br/>
      <div className="Bulletin-Card">
        <div className="Left">
          <h2>
            Easily Foldable <u>and</u> Removable
          </h2>
          <p>
            Our fully magnetic mechanism makes it easy to fold up your panel 
            to let light in &mdash; or to remove the panel completely to enjoy a full view
          </p>
          <p>
            Each panel can be installed in less than 2 minutes without any hand-tools or drilling! 
          </p>
        </div>
        <div className="Right">
            <ReactPlayer 
              playing url = 'blackout-on-off.mp4'
              width = "30vw"
              height = "37.33vw"
              controls={false}
              playing={true}
              loop="true"
            />
        </div>

      </div>
        <br/><br/><br/>
      <div className="Quote-Spotlight">
        <a href="https://www.nytimes.com/interactive/2023/06/09/health/noise-exposure-health-impacts.html" target="_blank">
          <div className="Outlink" >
            <div>New York Times on noise exposure</div>
            <img id="icon_linkto" src={icon_linkto}/>
          
          </div>
        </a>
        <div className="Quote">
          "night-time noise can fragment sleep and trigger a stress 
          response even if the person does not recall being roused"
        </div>
      <br/><br/>
        <h2>better sleep, better health with our Blackout Soundproof panels</h2>
      </div>
      <br/><br/>
      <br/><br/>

      <div id="get-in-touch"/>

      <GetInTouch size={props.size}/>
      <div style={{"height":"100px"}}/>
      </div>

  );
};