import { Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Invisible from './views/Invisible';
import Blackout from './views/Blackout';
import Payment from './views/Payment';
import PrivacyPolicy from './views/PrivacyPolicy';
import BlogDirectory from './views/BlogDirectory';

import BPA001 from './views/BlogPosts/A001';
import BPA002 from './views/BlogPosts/A002';
import BPA003 from './views/BlogPosts/A003';
import BPA004 from './views/BlogPosts/A004';

import { 
	WQ_PATH, WD_PATH, PAYMENT_PATH,
	BLOG_DIR_PATH, 
	BLOG_A001_PATH, BLOG_A002_PATH, BLOG_A003_PATH, BLOG_A004_PATH
} from './URL_PATHS';

export default function RoutesDef(props){
	return (
		<Routes>
			<Route path='/' element={<Home size={props.size}/>}/>
			<Route path={WQ_PATH} element={<Invisible size={props.size}/>}/>
			<Route path={WD_PATH} element={<Blackout size={props.size}/>}/>
			<Route path={PAYMENT_PATH} element={<Payment/>}/>
			<Route path="privacy-policy" element={<PrivacyPolicy/>}/>
			<Route path={BLOG_DIR_PATH} element={<BlogDirectory/>}/>
			<Route path={BLOG_A001_PATH} element={<BPA001/>}/>
			<Route path={BLOG_A002_PATH} element={<BPA002/>}/>
			<Route path={BLOG_A003_PATH} element={<BPA003/>}/>
			<Route path={BLOG_A004_PATH} element={<BPA004/>}/>
		</Routes>
	)
}