import { BreakpointProvider, Breakpoint} from 'react-socks';
import {BrowserRouter as Router} from 'react-router-dom';
import {useEffect} from 'react';
import './App.scss';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import CustomNav from './components/CustomNav';
import Footer from './components/Footer';
import RoutesDef from './RoutesDef';
import { AppContext } from "./libs/contextLib";


function App() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HelmetProvider>
    <BreakpointProvider>
      <Helmet>
        <title>Whisper Window | NYC Soundproofing and Blackout</title>
        <meta
          name="description"
          content="
            New York NYC soundproofing business with noise reducing and blackout 
            window panels. Products offer sound blocking, temperature insulation 
            and energy savings"
        />
      </Helmet>

      <Router>
        <Breakpoint medium up>
          <div className='Lg'>
            <CustomNav size="Lg"/>
            <div className="App">
              <RoutesDef size="Lg"/>
            </div>
            <Footer/>
          </div>
        </Breakpoint>
        <Breakpoint small down>
          <div className='Sm'>
            <CustomNav size="Sm"/>
            <div className="App">
              <RoutesDef size="Sm"/>
            </div>
            <Footer/>
          </div>
        </Breakpoint>
        
      </Router>
    </BreakpointProvider>
    </HelmetProvider>
  );
}

export default App;
