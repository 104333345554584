import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Component imports
import TrackedButton from '../../components/TrackedButton'
import TrackedLink from '../../components/TrackedLink'
import RelatedPosts from '../../components/RelatedPosts.js';
import BlogEnding from '../../components/BlogEnding.js';
import GetInTouch from '../../components/GetInTouch.js';

// Style imports 
import './BlogPost.scss';

// Image imports
import headerImage from'../../images/blog/man-room-noise.png';
import windowSoundwaves from '../../images/blog/window-soundwaves.png';
import budgetChecklist from '../../images/blog/budget-checklist.png';
import houseMoneyQuestionmark from '../../images/blog/house-money-questionmark.png';

// GA Tracking Lib
import { 
    gaButton2ContactForm, gaLink2ContactForm, 
    gaBlog2Home, gaBlog2BlogDir,
    gaButton2WQ, gaLink2WQ, gaButton2WD, gaLink2WD
} from '../../libs/trackingLib';


// URL  constants
import {
  BLOG_DIR_PATH, WQ_PATH, WD_PATH
} from '../../URL_PATHS'

export default function BPUltimateGuide1(props) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)

  }, [])

  return (
    <div className="Blog-Post">
      <Helmet>
        <title>The Ultimate Guide To Soundproofing Your Apartment</title>
        <meta
          name="description"
          content="
          Our most comprehensive guide on soundproofing, covering the essentials on 
          diagnosing your noise issue and top noise reduction solutions.
          "
        />
      </Helmet>

      <div className="Header">
        <div className="Left" style={{"backgroundColor":"#1E3C43"}}>
          <h1>The Ultimate Guide to Soundproofing your Apartment - Pt 1</h1>
          <h2 className="Subtitle">(and finally getting a good night's sleep)</h2>
          <TrackedButton
            url={BLOG_DIR_PATH}
            content="More blog posts"
            gaTrack={gaBlog2BlogDir}
          />
        </div>
        <div className="Right">
          <img
            alt="a man covering his ears from outside noises"
            id="header-img" src={headerImage} 
          /> 
        </div>
      </div>
      <div className="Body">
        <div className="Intro">
          <p>
            City living offers a vibrant lifestyle, but often comes with a 
            not-so-welcomed companion: noise.
          </p>
          <p>
            <b>Luckily, we've got you covered with this <u>comprehensive
             list of soundproofing tips and tricks</u>. </b> 
             With this knowledge, you'll be on your way to
             a peaceful home and more restful sleep in no time.
          </p>
        </div>
        <div className="TOC">
          <b>Table of Contents</b>
          <ol>
            <li>Understand Your Noise Situation</li>
            <ol>
              <li>What are the noises you hear, and where are they coming from?</li>
              <li>Determine your goals</li>
              <li>Set your budget & timeline</li>
            </ol>
            <br/>
            <li>Soundproofing Solutions for Every Budget & Timeline</li>
            <ol>
              <li>Budget solutions under $20</li>
              <li>DIY Everything</li>
              <li>Pay to do less</li>
              <li>Most effective solutions</li>
            </ol>
          </ol>
        </div>
        <h2>Understand Your Noise Situation</h2>
        <p>
          <b>We <u>cannot overstate</u> the importance of 
          taking some time to understand your noise 
          situation <u>before</u> taking any action. </b>  

        </p>
        <p>
          Noise enters and bounces around our home in a myriad of different ways. 
          Your soundproofing solution should be highly tailored to 
          not only the sounds you're trying to block out, but also your personal goals, budget, and timeline.
        </p>
        <p>
          Soundproofing can involve almost no cap in budget and effort. 
          Without first diagnosing your situation, it's possible that you wind up spending
          considerable time and money on something that barely made a difference. 
        </p>
        <h3>1. What are the noises you hear, and where are they coming from?</h3>
        <h4>Inside vs. Outside Noise</h4>
        <p className="TLDR">
          TLDR: Soundproof windows for street noise, doors for hallway noise, 
          and walls for neighbor noise
        </p>
        <p>
          The first distinction to make is between the noise from within and outside 
          of your building. If you’re mostly suffering from outside noises, 
          you’d likely benefit most from exploring various types of window treatments. 
          If you’re looking to block sounds coming from within your building, 
          you’ll need to target the issue more specifically. 
          For example, you might want to consider sealing and padding your door if 
          noise is coming from the hallways, whereas you would look to soundproofing 
          your walls if the noise is coming from a neighboring room.
        </p>
        <h4>High Frequency vs. Low Frequency Sound</h4>
        <p className="TLDR">
          TLDR: Use dense materials to block out high frequency sounds and thick 
          "fluffy" materials to absorb low frequency sounds
        </p>
        <p>
          <img className="right-float-img" id="window-soundwaves" src={windowSoundwaves}/>
          The sound-proofing method that’s most suitable for you depends on 
          the frequencies of the sound you’d like to block out. 
          To summarize our article that details how to soundproof against 
          high and low frequencies: denser materials will generally block out 
          high frequency sounds such as children’s crying while thicker “fluffy” 
          materials will generally be better at blocking out low frequency sounds 
          such as those from a bass or a train.
        </p>
        <p>
          However, more often than not, such as the case with construction 
          sites and fire truck sirens, you’re really getting loud noises in 
          both very high and very low frequencies. In those cases, 
          the best soundproofing option for urban dwellers tend to be products 
          that combine different materials with air gaps to block high and low frequencies. 
          For example, in our Whisper Dark Blackout Soundproof 
         Panels, we sandwich 5 layers of different materials together 
          to achieve superior soundproofing.
        </p>

        <h3>2. Determine your goals</h3>
        <p>
          Would you like to completely eliminate outside sound, or just dampen it? 
          Are you specifically targeting soundproof solutions to get deeper sleep 
          at night? Your goal will make a world of difference to your 
          budget and solution. 
        </p>
        <h4>It's essentially impossible to fully eliminate noise from your home</h4>
        <p>
          In general, while it’s definitely possible to dampen the noises of the city on a budget, 
          it is almost impossible to fully block out the sounds of sirens, and hammer drills. 
          The more you want to block out sound completely, the more you need to invest
          in a high-budget full renovation that likely involves adding layers and layers of insulation onto 
          your floors, walls, ceiling, windows and doors.
        </p>
        <h4>Luckily, there's a lot we can do to dampen sound to healthy levels</h4>
        <p>
          For most of us, our soundproofing goals tend to just be to 
          mitigate the noise levels we experience to a healthy range 
          like 30-40 decibels so that we could achieve a certain level of 
          tranquility in our daily lives and sleep. 
          And that goal is certainly achievable, even in the busier parts of the city.
        </p>
        <h4>As with everything, there are diminshing returns in noise reduction</h4>
        <p>
          Reducing the noise
          you hear by 50% of will likely quire more than double the effort 
          than reducing noise by 25%. Reducing the intensity of the sounds you hear 
          by 50% is possible, but reducing the intensity by 100% is not.
        </p>

        <h3>3. Set your budget and timeline</h3>
        <img className="medium-full-img" src={budgetChecklist}/>

        <h4>Days vs. Months</h4>
        For every soundproofing need, there is likely always solutions 
        you can put up yourself in a matter of days as well as ones 
        that takes months of work from a whole construction team. 

        <h4>Low-lift solutions may be a great fit if</h4>
        <ol>
          <li>You're looking to reduce the sound you hear by 25%-50%</li>
          <li>You're renting</li>
          <li>You're on a budget</li>
          <li>Your HOA have a difficult process for approving structural changes</li>
          <li>You're in a landmarked building that requires a permit for certain structural changes</li>
          <li>You are looking for the easiest way to solve your noise issues</li>
        </ol>

        <h4>High-lift solutions may be a great fit if</h4>
        <ol>
          <li>You're looking to reduce the sound as much as possible</li>
          <li>You're looking for a "forever solution" in your long-term home</li>
          <li>You are prepared to budget and wait for a large renovation project</li>
          <li>You have ample space and can accomodate added layers of walls, windows, and doors</li>
        </ol>


        <h4>In soundproofing, expensive doesn't always mean effective</h4>
        <img className="right-float-img" id="house-money-questionmark" src={houseMoneyQuestionmark}/>
        <p> 

          <u>To be clear:</u> Quality soundproofing materials will cost you.
          <br/>Having a dense material, or 
          a thick material is key effective soundproofing -- 
          all that weight, volume, and R&D come with a price tag.
        </p>
        <p>
          However, even if you buy a quality product. They way it's installed
          could render the product completely ineffective. Furthermore, cheap product installed 
          in the right places can sometimes be surprisingly effective. 
          Before you embark on your soundproofing journey, it's important to explore 
          different soundproofing options and be aware of the common pitfalls that has led 
          to many people spending on big-ticket soundproofing items that ended up not
          really working.
        </p>
      </div>
      <br/>
      <div className="Read-More">
        <RelatedPosts post_id="A003"/>
      </div>
      <div className="Reach-Out">
        <BlogEnding/>
      </div>

      <GetInTouch size={props.size}/>




      <div style={{"height":"100px"}}>
      </div>

    </div>
  );
}