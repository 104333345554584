import { useNavigate } from 'react-router-dom';

import './ProductCard.scss';
import whisperInvisible from '../images/invisible-gif-slow.gif';
import whisperBlackout from '../images/blackout-gif-slow.gif';

import ReactGA from "react-ga4";


export default function ProductCard(props) {
	const navigate = useNavigate();

	const onProductClick = () => {
		navigate(props.link);
		props.gaTrack()
	}

	return (
		<div className="ProductCard" onClick={onProductClick}>
			{ props.src=="Whisper Quiet" ? 
	          <img 
	          	alt={props.alt}
	          	id="whisper-invisible" src={whisperInvisible} /> 
	          :
	          <img 
	          	alt={props.alt}
	          	id="whisper-blackout" src={whisperBlackout} />
	        }
	        <div className="ProductDescription">
	        	<span className="soundproof-label">{props.name1}</span>
				<span className="product-name">{props.name2}</span>
				<span style={{"display":"block","padding-bottom":"15px"}}>{props.des}</span>
				<button>Learn More</button>
			</div>
		</div>
	)
}