import { POST_META } from './views/BlogPosts/RELATED_POSTS_MAPPING';

export const WQ_PATH = '/whisper-quiet-clear-soundproof-insert'
export const WD_PATH = '/whisper-dark-blackout-soundproof-panel'

export const PAYMENT_PATH = '/payment'


export const APPOINTMENT_PATH = 'https://calendly.com/whisperwindows/20min'

export const BLOG_DIR_PATH = "/blog"
export const BLOG_A001_PATH = "/blog"+POST_META['A001'].url
export const BLOG_A002_PATH = "/blog"+POST_META['A002'].url
export const BLOG_A003_PATH = "/blog"+POST_META['A003'].url
export const BLOG_A004_PATH = "/blog"+POST_META['A004'].url