// Hook imports
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// Component imports
import TrackedButton from '../components/TrackedButton'
import GetInTouch from '../components/GetInTouch.js';

// Style imports
import "./ProductPage.scss"

// Image imports
import backTriangle from '../images/back-triangle.png';
import invisibleHeader from '../images/invisibleHeader.png';
import icon_noiseBlocking from '../images/icon-noiseblocking.png';
import icon_uvDraft from '../images/icon-uvdraft.png';
import icon_temperature from '../images/icon-temperature.png';
import icon_nyc from '../images/icon-nyc.png';
import icon_linkto from '../images/icon-linkto.png';
import invisible_hand from '../images/invisible-hand.jpg';
import invisible_hand2 from '../images/invisible-hand2.jpg';
import invisible_hand2_mobile from '../images/invisible-hand2-mobile.jpg';
import invisible_rails from '../images/invisible-rails.jpg';

// GA Tracking Lib
import { 
    gaButton2ContactForm
} from '../libs/trackingLib';

// URL  constants
// import { WQ_PATH, WD_PATH } from '../URL_PATHS'

export default function Invisible(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Product-Page">
      <Helmet>
        <title>Whisper Window Clear Soundproof Inserts</title>
        <meta
          name="description"
          content="
          Whisper Window's no-drill soundproof inserts block out noise, dust
          and draft. 10-day lead time in NYC and 100% satisfaction guarantee."
        />
      </Helmet>

      <div className="back" onClick={()=>navigate('/')}>
        <img id="back-triangle" src={backTriangle}/> home
      </div>
      <div className="Product-Header">
        <h1>
          <span id="title-lead">WHISPER QUIET</span>
          <br/>Soundproof Insert
        </h1>
      </div>

      <div className="Product-Highlights">
        <div className="Highlight-Points">
          <div className="highlight-point">
            <img id="icon_noiseBlocking" src={icon_noiseBlocking}/>
            Noise Reduction
          </div>
          <div className="highlight-point">
            <img id="icon_temperature" src={icon_temperature}/>
            Insulation Savings
          </div>
          <div className="highlight-point">
            <img id="icon_uvDraft" src={icon_uvDraft}/>
            UV & Draft Blocking
          </div>
        </div>
      </div>
      <div className="Gray-Block">
        <h2 className="Blue-Text">See how our Soundproof Inserts Work</h2>
        <br/>
        <div className="Product-Video" onClick={()=>{console.log("testing")}}>
          <iframe 
            src='https://www.youtube.com/embed/hshEMEPhfU4'
            width="100%"
            height="100%"
            frameborder='0'
            allow='autoplay; encrypted-media'
            allowfullscreen
            title='video'
            />
          </div>
          <div className="get-quote-button">
            <TrackedButton
              scrollTo={"#get-in-touch"}
              gaTrack={gaButton2ContactForm}
              content="Get a quote today!"
            />
          </div>
</div>


      <div className="Feature-Highlight">
        <br/><br/><br/>
        <h2>Superior Insulation</h2>
        <br/>
        <p className="left-side">
          High quality plexiglass installed with air gaps larger than even triple-paned windows
        </p>
        <img id="invisible-hand" src={invisible_hand}/>
        <p className="left-side">
          Blocks out <b>up to 90% of noise</b> <br/>
          Keeps out dust, draft and cold weather
        </p>
        <br/><br/><br/>
        <h2>Easy Installation</h2>
        <br/>
        <p className="left-side">
          Ultra-thin edge maximizes light exposure<br/>
          creates truly "invisible" look
        </p>
        <img id="invisible-rails" src={invisible_rails}/>
        <p className="left-side">
          Tension based insert + adhesive safety rails<br/>
          No drill, no sodering, <b>no HOA approval needed</b>
        </p>
        <br/><br/><br/><br/>
      </div>
      <div className="Icon-Highlight">
        <img id="icon_nyc" src={icon_nyc}/>
        <h2>
          10-Days Made-To-Order<br/>
          <span className="title-lead">manufactured in NYC</span>
        </h2>
      </div>
      <br/><br/><br/><br/>
      <div className="Bulletin-Card">
        <div className="Left">
          <h2>
            <u>Save</u> on Energy and <u>Earn</u> on your Home
          </h2>
          <p style={{"margin-block-end":"-1.7em"}}><b>DID YOU KNOW</b></p>
          <p>
            Reducing noise levels can help your home appreciate by 5-10%
          </p>
          <p>
            Our Soundproof Insert can help your home appreciate while saving 
            you <u>additional</u> $$$ on energy every month!
          </p>
        </div>
        <div className="Right">
          { props.size=="Sm" ? 
            <img id="invisible-hand2-mobile" src={invisible_hand2_mobile}/>
            :
            <img id="invisible-hand2" src={invisible_hand2}/>
          }
        </div>

      </div>
        <br/><br/><br/><br/><br/><br/>
      <div className="Quote-Spotlight">
        <a href="https://www.nytimes.com/interactive/2023/06/09/health/noise-exposure-health-impacts.html" target="_blank">
          <div className="Outlink" >
            <div>New York Times on noise exposure</div>
            <img id="icon_linkto" src={icon_linkto}/>
          
          </div>
        </a>
        <div className="Quote">
          "increasing the risk of hypertension, stroke and heart attacks 
          for <u> more than 100 million Americans</u>"
        </div>
      <br/><br/>
        <h2>better sleep, better health with Whisper Window Inserts</h2>
      </div>
      <br/><br/>
      <br/><br/>

      <div id="get-in-touch"><br/><br/><br/></div>
      <GetInTouch size={props.size}/>
      <div style={{"height":"100px"}}/>
      </div>

  );
};