// Hook imports
import {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Component imports
import TrackedButton from '../../components/TrackedButton'
import TrackedLink from '../../components/TrackedLink'
import RelatedPosts from '../../components/RelatedPosts.js';
import BlogEnding from '../../components/BlogEnding.js';
import GetInTouch from '../../components/GetInTouch.js';

// Style imports 
import './BlogPost.scss';

// Image imports
import headerImage from'../../images/blog/man-room-noise.png';
import caulkGun from '../../images/blog/caulk-gun.png';
import sealingTape from '../../images/blog/sealing-tape.jpg';
import windowDraftStopper from '../../images/blog/window-draft-stopper.png';
import flutedPolycarb from '../../images/blog/fluted-polycarb.png';
import soundDampeningBlanket from '../../images/blog/sound-dampening-blanket.png';
import whisperQuiet from '../../images/invisible-gif-slow.gif';
import couchRugTapestry from '../../images/blog/couch-rug-tapestry.png';
import acousticFoam from '../../images/blog/acoustic-foam.png';
import wall3painting from '../../images/blog/wall-3painting.png';
import feltWall from '../../images/blog/felt-wall.png';
import earplugs3m from '../../images/blog/earplugs-3m.png';
import earplugsMacks from '../../images/blog/earplugs-macks.png';
import earplugsPq from '../../images/blog/earplugs-pq.png';
import earplugsBose from '../../images/blog/earplugs-bose.png';
import budgetChecklist from '../../images/blog/budget-checklist.png';
import houseMoneyQuestionmark from '../../images/blog/house-money-questionmark.png';

// GA Tracking Lib
import { 
    gaButton2ContactForm, gaLink2ContactForm, 
    gaBlog2Home, gaBlog2BlogDir,
    gaButton2WQ, gaLink2WQ, gaButton2WD, gaLink2WD,
    ga2Amazon, ga2Partner
} from '../../libs/trackingLib';


// URL  constants
import {
  BLOG_DIR_PATH, WQ_PATH, WD_PATH
} from '../../URL_PATHS'



export default function BPA002(props) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className="Blog-Post">
      <Helmet>
        <title>4 Easy Ways to Keep out Street Noise</title>
        <meta
          name="description"
          content="
          Maybe you just moved to NYC and the noise is driving you nuts.
          No worries, we've got you covered with our top tried-and-true soundproofing tips.
          "
        />
      </Helmet>

      <div className="Header">
        <div className="Left" style={{"backgroundColor":"#1E3C43"}}>
          <h1>Help! My apartment is loud AF.</h1>
          <h2 className="Subtitle">4 Easy Ways to Keep Out Street Noise in NYC</h2>
          <TrackedButton
            url={BLOG_DIR_PATH}
            content="More blog posts"
            gaTrack={gaBlog2BlogDir}
          />
        </div>
        <div className="Right">
          <img
            alt="a man covering his ears from outside noises"
            id="header-img" src={headerImage} 
          /> 
        </div>
      </div>
      <div className="Body">
        <div className="Intro">
          <p>
            You just moved to New York, and the noise is driving you nuts. Maybe when you signed the 
            lease you didn't realize that you lived right above a bar. Maybe they 
            just started construction next to you. Or maybe you live on 1st Ave and
            spend your nights waking up repeatedly to sirens and helicopters.
          </p>
          <p>
            Wherever you are, noise sucks. Constant noise is not just 
            deeply infuriating, but incredibly bad for your health.
          </p>
          <p>
            <b>Luckily, no matter who you are or what your budget is,
            there are steps that <u>everyone</u> can take to
            keep noises out of our homes.</b> 
          </p>
        </div>
        <div className="TOC">
          <b>A Step-to-Step Guide to Blocking Out Noise</b>
          <ol>
            <li>Seal the Cracks</li>
            <li>Get soundproof window inserts</li>
            <li>Put up sound absorbing materials</li>
            <li>Ear plugs make everything better</li>
          </ol>
        </div>
        <h3>1. Seal the Cracks</h3>
        <p className="TLDR">
          In general, sound travels where air goes. Cold air, drafts, 
          and dust do too.
        </p>
        <p>
          <b>Re-sealing or double-sealing your window is likely the biggest 
          "bang for the buck"</b> when it comes to 
          soundproofing, <u>especially</u> if you have older windows.
          </p>
          <p>Properly sealing on your windows not only helps with noise, but 
          have the added benefits of 
          <ul>
            <li>
            <b>Improved temperature insulation</b>, which can 
            help block out icy winter drafts and save you money on energy bills
            </li>
            <li>
            <b>Dust-blocking</b>, which can greatly reduce the 
            frequency you need to dust and clean
            </li>
          </ul>
        </p>
        <p>
        <br/>
        It's generally affordable and easy to seal 
        windows and doors. Here are some options
        </p>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST FOR OLDER WINDOWS
            </h4>
            <h4 className="product-label">
              Re-caulking Windows and Doors
            </h4>
            
            <p>
              <b>Pros:&nbsp;</b> Effective and cheap
              <br/>
              <b>Cons:</b> Takes a few hours and require basic tools 
              + handywork awareness
            </p>
            <p>         
              Caullking your windows
              is really important to for blocking out
              moisture, dust, draft, and noise. 
              All caulking deteriorates with time, and caulking generally 
              lasts for around 5-10 years before they need to be replaced. 
            </p>
            <p>
              <b>How do you know that it's time to recaulk?</b>
              <ul>
                <li>If you notice visible cracks along your window caulking</li>
                <li>If you are noticing cold drafts around the edges of your window and window frame</li>
              </ul>
            </p>
            <p>
              <b>DIY Re-Caulking:</b>
              <ul>
                <li><b>Cost:</b> ~$20-$50</li>
                <li><b>Time:</b> 2-3 hours per window</li>
                <li>
                  <b>Skill-Level:</b> Hard if you don't consider yourself a 
                  "handy" person. Easy if you know your way around a caulk gun.
                </li>
              </ul> 
              <br/>
              <b>Hiring A Contractor:</b> ~$75 in NYC per window
              <span className="Quote-Shortcut-Button">
                <TrackedButton 
                  scrollTo="#get-in-touch"
                  content="Secure your quote!"
                  gaTrack="gaButton2ContactForm"
                />
              </span>
            </p>
            <p className="TLDR">
            Tip: Get FREE window sealing with every <TrackedLink
              url={WQ_PATH}
              target="_blank"
              content={"WhisperQuiet Soundproof Insert"}
              gaTrack={gaLink2WQ}
            /> purchase 
            </p>
          </div>
          <div className="Image-Content">
            <img src={caulkGun}/>
          </div>
        </div>
        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST FOR RENTERS
            </h4>
            <h4 className="product-label">
              Window & Door Sealing Tape
            </h4>
            
            <p>
              <b>Pros:&nbsp;</b> Effective, cheap, easy to put up
              <br/>
              <b>Cons:</b> More temporary solution and can prevent windows from opening
            </p>
            <p>
              Window sealing tape is a great way to seal your windows 
              easily for when you don't need to open them. Many people
              put up the tape in the winters to protect against the cold. 
              But they also seal any cracks in your windows do wonders 
              to block out noise from the outside. 
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/XFasten-Transparent-Weather-Sealing-Isolation/dp/B07PDRY2T2"
                target="_blank"
                content="Amazon Link"
                gaTrack="ga2Amazon"
              />
            </p>
            <p className="TLDR">
              Tip: Get FREE window sealing with every <TrackedLink
                url={WQ_PATH}
                target="_blank"
                content="WhisperQuiet Soundproof Insert"
                gaTrack="ga2=Link2WQ"
              /> purchase 
            </p>
          </div>
          <div className="Image-Content">
            <img src={sealingTape}/>
          </div>
        </div>
        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              WINDOWS THAT OPEN + DOORS
            </h4>
            <h4 className="product-label">
              Draft Stoppers
            </h4>
            <b>Pros:&nbsp;</b> Versatile, allows windows and doors to open
              <br/>
              <b>Cons:</b> Tend to become loose relatively quickly and requires reapplication
            <p>
              Most draft stoppers have adhesives along only half of its width.
              The non-adhesive part of the draft stopper covers any crack while allowing 
              the window or door to move freely.
            </p>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/Stopper-Adhesive-Insulation-Stripping-Soundproof/dp/B08NG6PKDC"
                target="_blank"
                content="Our favorite window draft stopper"
                gaTrack={ga2Amazon}
              />
            </p>
            <p>
              <b>Buy Now: </b> 
              <a 
                href="https://www.amazon.com/Silicone-Sealing-Weatherproof-Soundproof-Adhesive/dp/B07V577GWG"
                target="_blank"
              >
              Our favorite door draft stopper
              </a>
            </p>
            <p className="TLDR">
              Tip: Get FREE window sealing with every <TrackedLink
                url={WQ_PATH} target="_blank"
                content="WhisperQuiet Soundproof Insert"
                gaTrack={ga2Amazon}
              /> purchase 
            </p>
          </div>
          <div className="Image-Content">
            <img src={windowDraftStopper}/>
          </div>
        </div>

        <h3>2. Get Soundproofing Window Covers / Inserts</h3>
        <p>
          Adding another layer to your window is without a doubt one of 
          the most effective things you can do to block out noise 
          from the outside. These products vary greatly, from budget to premium, 
          and from no-drill to involving heavy soldering and structural changes.  
        </p>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST BUDGET
            </h4>  
            <h4 className="product-label">
              Magnetic Soundproofing Window Covers
            </h4>
            <p>
              <b>Pros:&nbsp;</b> Effective, no-drill, lets light in
              <br/>
              <b>Cons:</b> Requires self installation (~15 minutes)
            </p>
            <p>
              Magnetic window covers are a new genre of innnovative 
              soundproofing products. They are a cheaper and easier alternative to 
              interior storm windows. 
            </p>
            <p>
              These magnetic window covers can block out up to 70% of sound.
            </p>
            <br/>
            <p>
              <b>Contact <TrackedLink
                scrollTo="#get-in-touch"
                content="Whisper Window"
                gaTrack={gaLink2ContactForm}
              /> for a Magnetic Soundproof Cover, starting $85</b> 
            </p>
          </div>
          <div className="Image-Content">
            <img src={flutedPolycarb}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BUDGET NIGHTTIME
            </h4>  
            <h4 className="product-label">
              Sound Dampening Blankets
            </h4>
            <p>
              <b>Pros:&nbsp;</b> One of the cheapest options for effective soundproofing
              <br/>
              <b>Cons:</b> Blocks out natural light during the day, must drill to install
            </p>
            <p>
              While they don't look the best, sound dampening blankets can effectively
              block out outside noise
            </p>
            <p>
              Tip: For superior sound effect, we recommend vecroing the sides of the 
              blankets against the wall or window for a full seal. To fully DIY this project,
              you can also attach velcro to 1-2 layers of moving blankets.
            </p>

            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/Dampening-Soundproof-Acoustic-Blocking-Absorption/dp/B0B5C5H7SP/"
                target="_blank"
                content="Amazon, starting $43"
                gaTrack={ga2Amazon}
              />
            </p>
          </div>
          <div className="Image-Content">
            <img src={soundDampeningBlanket}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST NO-DRILL
            </h4>  
            <h4 className="product-label">
              Soundproofing Window Inserts
            </h4>
            <p>
              <b>Pros:&nbsp;</b> Effective, no drill, completely clear
              <br/>
              <b>Cons:</b> Must remove insert to open and close windows
            </p>
            <p>
              Window inserts are highly effective at soundproofing and 
              work as an additional layer that goes on top of your 
              existing windows. 
            </p>
            <p>
              At Whisper Window, we make competely clear, <TrackedLink
                url={WQ_PATH}
                target="_blank"
                content="high quality window inserts"
                gaTrack="gaLink2WQ"
              /> that are <b>3x thicker</b> than average windows 
              and <b>eliminate 90% of sound</b> while
              blocking out dust and improving temperature insulation.
            </p>
            <br/>
            <p>
              We are dedicated to serving NYC with <b>industry-leading rates 
              and fast 10-day lead time</b>. It only takes 30 seconds 
              to <b><TrackedLink
                scrollTo="#get-in-touch"
                content="get a quote"
                gaTrack={gaLink2ContactForm}
                /></b> from us today. 
            </p>
            <span className="Quote-Shortcut-Button-Newline">
              <TrackedButton
                scrollTo="#get-in-touch"
                content="Secure your quote!"
                gaTrack={gaLink2ContactForm}
              />
            </span>
          </div>
          <div className="Image-Content">
            <img src={whisperQuiet}/>
          </div>
        </div>
        <div className="Product-Rec">
          <div className="Text-Content">
          <h4 className="label-category">
            BEST LONG-TERM
          </h4>  
          <h4 className="product-label">
            Window Replacements or Second Window Layer
          </h4>
          <p>
            <b>Pros:&nbsp;</b> Most effective, great long-term solution for your "forever home"
            <br/>
            <b>Cons:</b> Most expensive, requires structural changes and HOA approval
          </p>
          <p>
            Many window makers will create and install and additional layer of windows 
            for you on top of your existing windows. While these added window layers 
            come at a hefty cost, 
            they can be incredibly effective and a worthy investment for your forever
            home.
          </p>
          <p>
            This is something where, if you are extremely noise sensitive, you can go all out and 
            install an additional layer of triple-paned windowes <em>on top of</em> existing
            double-paned windows. 
          </p>
          </div>
        </div>

        <br/><br/><br/><br/><br/>


        <h3>3. Put up Sound Absorbing Materials</h3>
        <p>
        Sealing windows and adding window layers <em>blocks</em> sound. These
        fixes are designed to <em>deflect</em> sound waves entering the home and
        usually involve tight seals and dense materials. <b>Sound 
        absorbing materials, 
        on the other hand, are designed to let soundwaves enter it to be 
        absorbed and tend to be less dense and more "fluffy". 
        </b>
        </p>
        <p>
        The most effective soundproofing solutions tend to
        pair sound-blocking and sound-absorbing products together. 
        Sound blocking products will block out a
        signifcant amount of sound from entering your home in the first place, 
        while sound absorbing 
        materials dampen noise and stop it from bouncing around 
        once it gets inside your home.
        </p>
        
        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST MULTI-PURPOSE
            </h4>  
            <h4 className="product-label">
              Rugs and Tapestries
            </h4>
            <p>
              <b>Pros:&nbsp;</b> Low lift to furnish apartment with every-day interior design elements 
              <br/>
              <b>Cons:</b> Hard to quantify exact effectiveness
            </p>
            <p>
              Soft furniture, blankets, carpets, and rugs are natural sound abosrbers! 
              Opting for a hanging tapestries instead of paintings, or drapery instead of 
              shades can greatly help to dampen sound in a particular loud apartment. 
            </p>
          </div>
          <div className="Image-Content">
            <img src={couchRugTapestry}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST BUDGET
            </h4>
            <h4 className="product-label">
              Off-Brand Acoustic Foam
            </h4>
            <p>
              <b>Pros:&nbsp;</b> By far the cheapest we were able to find out of easily accessible options
              <br/>
              <b>Cons:</b> Do not look nice, lower performance compared to some well-known brands acoustic foams
            </p>
            <p>
              Acoustic foam does a great job at reducing echoes as it absorbs and 
              dampens sound bouncing inside a room.
            </p>
            <p>
              Tip: If the noise you hear is coming from another room or hallway, covering 
              the door or wall on the side of the noise source would be even more effective
              at reducing the sound levels in your room. 
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink 
                url="https://www.amazon.com/Acoustic-Panel-Wedge-Studio-Soundproofing/dp/B07C94ZZ57"
                target="_blank"
                content="Amazon Link"
                gaTrack={ga2Amazon}
              />
            </p>
          </div>
          <div className="Image-Content">
            <img src={acousticFoam}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              BEST DESIGN
            </h4>
            <h4 className="product-label">
              Art Acoustic Panels
            </h4>
            <p>
              <b>Pros:&nbsp;</b> High aesthetics appeal and doubles as art 
              <br/>
              <b>Cons:</b> Pricey
            </p>
            <p>
              Instead of buying art for your apartment, try custom
              acoustic panels that absorb noise while accentuating your style! 
            </p>
            <p>
              Acoustic panels are thick panels with layers of material created 
              with the expressed purpose to abosrb sound. They look great, but do 
              cost more than many cheaper sound absorbing methods.
            </p>
            <br/>
            <p>
              <b>
                At Whisper Window we make beautiful art print and 
                custom print acoustic panels starting at $95
              </b> 
            </p>
          </div>
          <div className="Image-Content">
            <img src={wall3painting}/>
          </div>
        </div>
        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              HONORABLE MENTION
            </h4>
            <h4 className="product-label">
              Felt Panels
            </h4>
            
            <p>
              <b>Pros:&nbsp;</b> Balances aesthetics with full-wall soundproofing needs
              <br/>
              <b>Cons:</b> Can be a bit pricey to cover a full wall
            </p>
            <p>
              Thick felt is an excellent sound abosrption material. Even better, they are
              available in all types of sizes and colors through a large number of vendors. 
            </p>
            <p>
              Tip: For those on a budget, try purchasing a large role of thick, hard felt 
              directly from an industrial felt vendor. Many direct-to-consumer felt brands
              will charge a premium for their branding and superior designs and services.
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              Our favorite felt tile brand is <TrackedLink 
                url="https://www.feltright.com"
                target="_blank"
                content="Felt Right"
                gaTrack={ga2Partner}
              /> because of its many color and shape options 
            </p>
          </div>
          <div className="Image-Content">
            <img src={feltWall }/>
          </div>
        </div>

        <h3>4. Ear plugs make everything better</h3>
        <p>
        Are ear plugs uncomfortable? Yes. But boy do they block out sound. 
        It's always worth having a few pairs of earplugs laying around to 
        block out some particularly loud hammerdrilling or getting some quality 
        shut-eye before a big event. 
        </p>
        <p>
        <b>There are a lot of different types of ear plugs, and the earplugs 
        that are most comfortable is highly personal. More expensive doesn't 
        necessarily mean a better fit for you.</b>
        </p>
        <p>
        We recommend trying at least 4-5 different types of ear plugs 
        to find some that could potentially work well for you. <em>The  
        search for comfortable ear plugs requires minimal investment and time, 
        but can be a complete game changer.</em>
        </p>

        <p>
        Here are some suggestions from us.
        </p>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              FOAM EARPLUGS
            </h4>
            <h4 className="product-label">
              3M 1100 Foam Ear Plugs
            </h4>
            <p>
              These 3M earplugs block out 37 decibels of sound when worn properly.
              It uses a hypoallergenic foam that's harder and easier to insert propertly
              but softens with body heat to provide a comfortable fit.  
            </p>
            <p>
              They're great value at just $0.13 a pop (but you gotta by a 200-pack).
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/3M-1100-Foam-Plugs-200-Pair/dp/B008MVYL7C"
                target="_blank"
                content="Amazon Link"
                gaTrack={ga2Amazon}
              />
            </p>
          </div>
          <div className="Image-Content">
            <img src={earplugs3m}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              FOAM ULTRASOFT
            </h4>
            <h4 className="product-label">
              Mack's Ultrasoft Foam Earplugs
            </h4>
            <p>
              The Mack's Ultrasoft Foam Earplugs block out 33 decibels of sound
              when worn correctly. While it does not block out quite as much sound 
              as the 3M earplugs, they win in comfort for those who prefer softer
              earplugs.
            </p>
            <p>
              These earplugs are still very affordable at just $0.28 a pair for a 50-pack.
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/Macks-Ultra-Soft-Foam-Earplugs/dp/B0051U7W32"
                target="_blank"
                content="Amazon Link"
                gaTrack={ga2Amazon}
              />
            </p>
          </div>
          <div className="Image-Content">
            <img src={earplugsMacks}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              WAX / SILICONE
            </h4>
            <h4 className="product-label">
              PQ Wax Earplugs
            </h4>
            <p>
              For those who find foam earplugs uncomfortable, wax and silicone 
              earplugs might be the way to go. Wax earplugs are moulded to cover 
              the ear hole, and do not protrude outwards or go deep into the ear canal. 
              As a result, many people find wax earplugs a lot more comfortable than 
              foam altermatives.
            </p>
            <p>
              What's worth noting is that wax and silicone ear plugs somes do not block noise 
              as effectively as foam earplugs do (especially with lower frequency sounds like 
              engines). Wax earplugs tend to be a bit more pricey than foam earplugs, but are 
              still generally easily affordable.
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/Wax-Ear-Plugs-Sleep-Cancelling/dp/B0BKH1D62C"
                target="_blank"
                content="Amazon Link"
                gaTrack={ga2Amazon}
              />
            </p>
          </div>
          <div className="Image-Content">
            <img src={earplugsPq}/>
          </div>
        </div>

        <div className="Product-Rec">
          <div className="Text-Content">
            <h4 className="label-category">
              LUXURY
            </h4>
            <h4 className="product-label">
              Bose QuietComfort Earbuds II
            </h4>
            <p>
              Outside of the everyday foam, wax, and silicone ear plugs, several 
              tech and audio companies also have headphones with 
              active noise cancelling (ANC) functionalities that can help too effectively 
              block out noise.
            </p>
            <p>
              The Bose QuietComfort Earbuds II, for example, boasts a snug and 
              comfortable design with the best ANC technology on the market. However, 
              all this tech do tend to come with a hefty price tag between $200 - $300+. 
            </p>
            <br/>
            <p>
              <b>Buy Now: </b> 
              <TrackedLink
                url="https://www.amazon.com/Bose-QuietComfort-Cancelling-Personalized-Cancellation/dp/B0B4PSQHD5"
                target="_blank"
                content="Amazon Link"
                gaTrack={ga2Amazon}
              />
            </p>
          </div>
          <div className="Image-Content">
            <img src={earplugsBose}/>
          </div>
        </div>
      </div>
      <br/>
      <div className="Read-More">
        <RelatedPosts post_id="A002"/>
      </div>
      <div className="Reach-Out">
        <BlogEnding/>
      </div>
      
      <GetInTouch size={props.size}/>

      <div style={{"height":"100px"}}>
      </div>

    </div>
  );
}