import './Payment.scss';

export default function Payment(){
	const payment_link = "https://checkout.stripe.com/c/pay/cs_live_a1VVJMwj52NARwi9QjF7fAGxN310tmhwztVgf1jm5T5BIznMIMfiCRJoFE#fidkdWxOYHwnPyd1blppbHNgWjA0SjBmNWJPZjxkYGxRfVJXZjdoNmo9XHdifFx9blNMTFJAM28yZn1jY3M2VUg9c1NoUTddNVUyZE9VZ0JHQEJ3bGRRbkBoX0NCdWo9a11udGRwXEFTck5QNTVmfHR%2FY31EVCcpJ3VpbGtuQH11anZgYUxhJz8nNDFuN2duNUZ%2FYktqMERuZkhIJ3gl"
	return (
		<div className='Payment'>
			<h2 className="Brown-Text">Credit Card Payment Link</h2>
			<br/><br/>
			<p>Note: There is 4% Credit Card Surcharge</p>
			<p>Payment via Zelle and Check is free</p>
			<br/><br/>

			<button>
				<a href={payment_link} target="_blank">
					Click to be re-directed to secure payment link
				</a>
			</button>
		</div>
	)
}