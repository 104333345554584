// Hook imports
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet-async';

// Component imports
import TrackedButton from '../components/TrackedButton'

// Style imports 
import './BlogDirectory.scss';

// Image imports
import spotlightImage from'../images/blog/man-room-noise.png';
import BPA004 from'../images/blog/earplugs-foams.png';
import BPA001 from '../images/blog/nursery.png';
import BPA002 from '../images/blog/man-room-noise.png';

// GA Tracking Lib
import { 
    gaLink2ContactForm, gaButton2BlogPost
} from '../libs/trackingLib';

// URL  constants
import {
  BLOG_A001_PATH, BLOG_A002_PATH, BLOG_A003_PATH, BLOG_A004_PATH
} from '../URL_PATHS'

export default function BlogDirectory(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const trackPostClick = () => {  
    ReactGA.event({
      category: "Click",
      action: "Blog Directory Click on Post"
    })
  }

  return (
    <div className="Blog-Directory">
      <Helmet>
        <title>Whisper Window Soundproofing Blog</title>
        <meta
          name="description"
          content="
          Top tried-and-tested strategies for soundproofing. Try some of
          our tips and tricks to reduce noise and bring peace to your home!"
        />
      </Helmet>
      <h1>Blog Posts</h1>
      <div className="Post-Spotlight" onClick={trackPostClick}>
        <div className="Left">
          <img
            alt="a man covering his ears from outside noises"
            id="spotlight-img" src={spotlightImage} 
          />
        </div>
        <div className="Right">
          <h1>The Ultimate Guide to Soundproofing your Home</h1>
          <p>
          We take you through diagnostics to looking at projects through different 
          difficulty levels and price ranges
          </p>
          <button>
            <a href={BLOG_A003_PATH}>
              Read More
            </a>
          </button>
        </div>

      </div>
      <div className="Post-List">
        <div className="Post" onClick={gaButton2BlogPost}>
          <img src={BPA004}/>
          <h2>Soundproofing for Every Budget & Need</h2>
          <button>
            <a href={BLOG_A004_PATH}>
            Read More
            </a>
          </button>
        </div>
        <div className="Post" onClick={gaButton2BlogPost}>
          <img src={BPA001}/>
          <h2>6 Steps to A Quiet and Dark Nursery</h2>
          <button>
            <a href={BLOG_A001_PATH}>
            Read More
            </a>
          </button>
        </div>
        <div className="Post" onClick={gaButton2BlogPost}>
          <img src={BPA002}/>
          <h2>4 Easy Ways to Block Outside Noises</h2>
          <button>
            <a href={BLOG_A002_PATH}>
            Read More
            </a>
          </button>
        </div>
      </div>


      

      
      


      <div style={{"height":"100px"}}>
      </div>

    </div>
  );
}