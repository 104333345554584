import ReactGA from "react-ga4";

// Contact Form Tracking 
export const gaContactFormStart = () => {
  ReactGA.event({
    category: "Form",
    action: "start_contact_form"
  })
}

export const gaContactFormSubmit = () => {
  ReactGA.event({
    category: "Form",
    action: "submit_contact_form"
  })
}


// User clicks button that scrolls to contact form part of page
export const gaButton2ContactForm = () => {
  ReactGA.event({
    category: "Click",
    action: "click_button_scroll_to_contact_form"
  })
}

// User clicks link that scrolls to contact form part of page
export const gaLink2ContactForm = () => {
  ReactGA.event({
    category: "Click",
    action: "click_link_scroll_to_contact_form"
  })
}

export const gaNavbarAppointmentClick = () => {
  ReactGA.event({
    category: "Click",
    action: "navbar_appointment_click"
  })
}

export const gaContactFormAppointmentClick = () => {
  ReactGA.event({
    category: "Click",
    action: "contact_form_appointment_click"
  })
}

export const gaButton2AppointmentClick = () => {
  ReactGA.event({
    category: "Click",
    action: "button_appointment_click"
  })
}

export const gaButton2WQ = () => {
  ReactGA.event({
    category: "Click",
    action: "click_button_to_WQ"
  })
}

export const gaLink2WQ = () => {
  ReactGA.event({
    category: "Click",
    action: "click_link_to_WQ"
  })
}

export const gaButton2WD = () => {
  ReactGA.event({
    category: "Click",
    action: "click_button_to_WD"
  })
}

export const gaLink2WD = () => {
  ReactGA.event({
    category: "Click",
    action: "click_link_to_WD"
  })
}


export const gaButton2BlogPost = () => {
  ReactGA.event({
    category: "Click",
    action: "click_to_blog_post"
  })
}

export const gaBlog2BlogDir = () => {
  ReactGA.event({
    category: "Click",
    action: "blog_to_blog_dir"
  })
}

export const gaContactFormToBlog = () => {
  ReactGA.event({
    category: "Click",
    action: "contact_form_to_blog_dir"
  })
}

export const ga2Amazon = () => {
  ReactGA.event({
    category: "Click",
    action: "to_amazon"
  })
}

export const ga2Partner = () => {
  ReactGA.event({
    category: "Click",
    action: "to_partner_site"
  })
}


export const gaBlog2Appointment = () => {
  ReactGA.event({
    category: "Click",
    action: "blog_to_appointment"
  })
}

export const gaBlog2Home = () => {
  ReactGA.event({
    category: "Click",
    action: "blog_to_Home"
  })
}

export const gaRelatedPostClick = () => {
  ReactGA.event({
    category: "Click",
    action: "related_post_click"
  })
}


