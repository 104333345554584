import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { GoogleReCaptcha,  } from 'react-google-recaptcha-v3';

import './GetInTouch.scss';

import { 
  gaContactFormStart, gaContactFormSubmit, gaContactFormAppointmentClick,
  gaContactFormToBlog
} from '../libs/trackingLib'
import { APPOINTMENT_PATH, BLOG_DIR_PATH } from '../URL_PATHS';
import { CONTACT_EMAIL, CONTACT_PHONE } from '../GLOBAL_VARS';
import TrackedLink from './TrackedLink';


export default function GetInTouch(props){
  const form = useRef();
  const [name, setName] = useState('') // honeypot
  const [email, setEmail] = useState('') // honeypot
  const [phone, setPhone] = useState('')
  const [mingzi, setMingzi] = useState('')
  const [youjian, setYoujian] = useState('')
  
  const [notes, setNotes] = useState('')
  const [formError, setFormError] = useState('')
  const [blushFactor, setBlushFactor] = useState('') // this is an invisible field for bots
  const [formStart, setFormStart] =useState(false)
  
  const formChange = () => {  
    if (!formStart){
      gaContactFormStart()
      setFormStart(true)
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();

    // check name 
    if (mingzi == '' && name == ''){
      setFormError("Please enter your name");
    } else if (youjian == '' && email == ''){
      setFormError("Please enter an email address");
    } else if (phone == ''){
      setFormError("Please enter a phone number");
    } else if (
      (!(email.includes("@") && email.includes("."))) && 
      (!(youjian.includes("@") && youjian.includes(".")))
      ){
      setFormError("Please enter a valid email address")
    } else {
      setFormError("Form successfully submitted! If you do not receive a confirmation within 5 minutes, please check your spam folder")
      setName('')
      setEmail('')
      setPhone('')
      setNotes('')
      setMingzi('')
      setYoujian('')

      emailjs.sendForm(
      'service_whisperwindowllc', // service ID 
      'WW_Interest_Form', // template ID
      form.current, 
      'egb1SvF1WohXdGWd-'// public key
      )
      .then((results)=>{
        console.log(results.text);
        gaContactFormSubmit()
      },(error)=>{
        console.log(error.text);

      });
    }

    
  };

	return (
		<div className="Home-Block Gray">
      <div className="GetInTouch">
        <div className="Left">
          <h2 className="Blue-Text" style={{"margin-block-start":"0", "margin-block-end":"10px"}}>
          	Get in touch
          </h2>
          <div className="LargerText">
            Call or Text {CONTACT_PHONE}
            <br/>
            <a>{CONTACT_EMAIL}</a>
            { props.size==="Lg" ? <span><br/><br/><br/><br/></span>:<br/>}
            <button className="Brown"
            	style={{
            		"padding":"4px 15px 3px 15px", 
            		"font-size":"0.9em", 
            		"margin-left":"-3px", "margin-top":"5px"}}
            >
            	<a href={APPOINTMENT_PATH} target="_blank" onClick={gaContactFormAppointmentClick}>
                Free virtual appointment
              </a>
            </button>
          </div><br/><br/>
            <h2 className="Blue-Text" style={{"margin-block-end":"0","margin-block-end":"10px"}}>Learn more</h2>

          <div className="LargerText">
            <TrackedLink 
              url={BLOG_DIR_PATH}
              target="_self"
              content="Visit our Blog"
              gatTrack={gaContactFormToBlog}
            /><br/>
            FAQs
          </div>

        </div>
        <div className="Right">
          <span className="Gray-Subtitle">Request A Quote</span>
          <br/><br/>
          All consultations and measurements are <u>free of charge</u>. Please fill out the form below to receive an email or call back within 36 hours.
          <br/><br/><br/>
          <em style={{color:"#46889E"}}>
          (Or, text us a picture of your window at 626-818-9955 for an instant quote!)
          </em>

          
          
          <br/><br/><br/>
          <form ref={form} onSubmit={sendEmail} onChange={formChange}>
            <div className="douniwan">
              <label for="name">Name*</label><br/>
              <input className="big-form-label" type="text" id="name" name="name" value={name} onChange={(e)=>setName(e.target.value)}/>
              <br/><label for="email">Email*</label><br/>
              <input className="big-form-label" type="text" name="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            <br/>Name*<br/>
            <input type="text" id="mingzi" name="mingzi" value={mingzi} onChange={(e)=>setMingzi(e.target.value)}/>
            <be/>Email*<br/>
            <input type="text" name="youjian" value={youjian} onChange={(e)=>setYoujian(e.target.value)}/>
            <br/>Phone Number*<br/>
            <input type="text" name="phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
            <br/>Add a note to us (optional)<br/>
            <textarea name="customer_notes" value={notes} onChange={(e)=>setNotes(e.target.value)}/>
            <span id="Form-Error">{formError}</span>
            <input type="submit" value="Send" style={{backgroundColor:"black"}}/>
          </form>

        </div>
        </div>
      </div>
		)
}