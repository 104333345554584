import './TrackedLink.scss'


export default function TrackedLink(props) {
	// props.url is the link to re-direct to
	// props.scrollTo is the div id to scroll to within the same page
	// props.target is '_blank' or "self"
	// props.gaTrack is a const function that pings Google Analytics

	return (
		props.url != null ?  // go to some other url
			<a className="Tracked-Link"
				href={props.url}
				target={props.target}
				onClick={props.gaTrack}
			> 
				{props.content}
			</a>
		: // scroll to something on the same page
			<a className="Tracked-Link"
				onClick={() => {
					props.gaTrack();
					window.location.replace(props.scrollTo);
				}}
			> 
				{props.content}
			</a>
	)
}