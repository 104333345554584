// Hook imports
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Component imports
import TrackedButton from '../components/TrackedButton'
import ProductCard from '../components/ProductCard';
import GetInTouch from '../components/GetInTouch.js';

// Style imports 
import './Home.scss';

// Image imports
import banner from '../images/banner.png';
import bannerMobile from '../images/banner-mobile.png';
import orderProcess from '../images/process.png'
import orderProcessMobile from '../images/process-mobile.png'

// GA Tracking Lib
import { 
    gaButton2ContactForm, gaButton2WQ, gaButton2WD
} from '../libs/trackingLib';

// URL  constants
import { WQ_PATH, WD_PATH } from '../URL_PATHS'



export default function Home(props) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={props.size}>
    <div className="Home">
      <div className="Top-Banner">
        <div className="Banner-Text-Background">
          
        </div >
        <div className="Banner-Text">
            <div>
              <h1>No-Drill Soundproof Window Panels</h1>
            </div>
            <div>
              <p><span className="emphasized">90%</span> noise reduction</p>
              <p><span className="emphasized">Free</span> measurement & installation</p>
            </div>
            <div className="Banner-Text-Bottom">
              <p 
                className="Orange-Text" 
                style={{"font-size":"1.15em", "font-weight":"600"}}>
                Starting $250
              </p>
              <TrackedButton
                scrollTo="#get-in-touch"
                gaTrack={gaButton2ContactForm}
                content="Get Quote"
              />
            </div>
            </div>
        { props.size==="Lg" ? 
          <div>
            <img 
              alt="a window with a clear sound and temperature insulation insert as well as a blackout panel"
              id="banner-img" src={banner} /> 
            </div>
          :
          <img 
            alt="a window with a clear sound and temperature insulation insert as well as a blackout panel" 
            id="banner-img-sm" src={bannerMobile} /> 
        }
      </div>

      <div className="Home-Block">
        <h2 className="Brown-Text">Window Panels in 10 days</h2>
        <h4 className="Brown-Text"><u>Free</u> professional measurement and installation</h4>

        { props.size==="Lg" ? 
          <img id="order-process" src={orderProcess} /> 
          :
          <img id="order-process-sm" src={orderProcessMobile} /> 
        } 
      </div>

      <h1 className="Brown-Text">Soundproofing Products</h1>

      <div className="Home-Block Tiles">
          <ProductCard 
            name1="WHISPER QUIET" 
            name2="Soundproof Insert"
            src="Whisper Quiet"
            des="No-drill, noise-reduction and temperature insulation"
            alt="The Whisper Invisible clear insert that offers soundproofing and temperature insulation"
            link={WQ_PATH}
            gaTrack={gaButton2WQ}
          />
          <ProductCard 
            name1="WHISPER DARK" 
            name2="Blackout Soundproof Panel"
            src="Whisper Dark"
            des="Removable night-time panel with art-print option"
            alt="Soundproofing and light-blocking Whisper Blackout panel; magnetic and easy to fold and remove"
            link={WD_PATH}
            gaTrack={gaButton2WD}
          />
      </div>

      

      {/*<h2 className="Blue-Text">About Our Products</h2>*/}


      
      <div className="Home-Block">
        <h2 className="Brown-Text">Why customers love Whisper Window</h2>
        <br/>
        <p><b>Beautiful & Thoughtful Design</b></p>
        <p>Up to 90% noise reduction
        <br/>
        Draft, dust, and temperature protection
        <br/>
        No drill 5-minute installations
        <br/>
        Aesthetic focused
        </p>
        <br/>
        <p><b>Dedicated to serving NYC</b></p>
        <p>
          Sourced and manufactured locally in Queens
          <br/>
          7-10 day lead times for all NYC orders
          <br/>
          Free uninstall and recycling within NYC
        </p>
        <br/><br/>
        <p className="Customer-Quote" style={{"line-height":"1.2rem"}}>
          "I got the invisible inserts and they have been doing wonders for my son's sleep <br/> 
          They were professional and thoughtful 
          during the ordering process." <br/><br/>
          - Shirley 
        </p>
      </div>
      

      <div id="get-in-touch"><br/><br/><br/></div>
      <GetInTouch size={props.size}/>
      


      <div style={{"height":"100px"}}>
      </div>

    </div>
    </div>
  );
}

