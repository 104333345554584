export const RELATED_POSTS_MAPPING = {
	"A001":["A002"],
	"A002":["A001", "A003"],
	"A003":["A004", "A002"],
	"A004":["A003", "A002"],
}

export const POST_META = {
	"A001":
	{
		"shortTitle":"6 Steps to A Quiet & Dark Nursery", 
		"url":"/6-steps-quiet-dark-nursery"
	},
	"A002":
	{
		"shortTitle":"4 Easy Ways to Keep Out Street Noise in NYC", 
		"url":"/4-easy-ways-block-street-noise-nyc"
	},
	"A003":
	{
		"shortTitle":"The Ultimate Guide to Soundproofing your Apartment", 
		"url":"/ultimate-guide-to-soundproofing-pt1"
	},
	"A004":
	{
		"shortTitle":"Soundproofing Solutions for Every Budget and Need", 
		"url":"/soundproof-solutions-every-budget-need"
	}
}
