import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../images/WW_Long_Logo.png';
import logo_icon from '../images/WW_logo.png';
import icon_phone from '../images/icon-phone.png'

// style & UI components
import './CustomNav.scss';

import { 
  gaNavbarAppointmentClick 
} from '../libs/trackingLib'

import { APPOINTMENT_PATH } from '../URL_PATHS'
import { CONTACT_EMAIL, CONTACT_PHONE } from '../GLOBAL_VARS'



export default function CustomNav(props) {
  // Other setup
  const navigate = useNavigate();

  return (
    props.size=="Lg" ? 
      <div className="NavBar">
          <div className="nav-section" id="nav-left" >
            <div className="img-container">
              <img alt="phone icon" src={icon_phone}/>
            </div>
            <span className="nav-option"> 
                {CONTACT_PHONE}
              </span>
          </div>
          <div className="nav-section" id="nav-center">
            <span className="title" onClick={()=>navigate('/')}>WHISPER WINDOW</span>
          </div>
          <div className="nav-section" id="nav-right">
                <span className="nav-button-dark" onClick={gaNavbarAppointmentClick}>
                  <a href={APPOINTMENT_PATH} target="_blank">
                    Free appointment
                  </a>
                </span>
                
          </div>
      </div> 
      : 
      <div className="NavBar">
        <div className="mobile-section">
          <div className="title" onClick={()=>navigate('/')}>
            WHISPER WINDOW
          </div>
          <div className="nav-section"> 
                {CONTACT_PHONE}
          </div>
        </div>
      </div> 
    );

}


