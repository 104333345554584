// Component imports
import TrackedLink from './TrackedLink' 
import TrackedButton from './TrackedButton' 

// Style imports 
import "./BlogEnding.scss"

// GA Tracking Lib
import { 
    gaButton2BlogPost, 
    gaButton2ContactForm, gaLink2ContactForm, gaButton2AppointmentClick
} from '../libs/trackingLib';

// URL  constants
import { APPOINTMENT_PATH } from '../URL_PATHS'

export default function BlogEnding(props){
	return (
	<div className="Blog-Ending">
        <h3>Why are you even reading this?</h3>
        <b>...when you could just get 
        a <u><TrackedLink
          scrollTo="#get-in-touch"
        	content="free consultation"
          gaTrack={gaLink2ContactForm}
        /> </u> from Whisper Window</b>. 
        <br/><br/>
        Our experts will listen to your needs and make recommendations 
        based on your unique home and soundproofing needs.
        <br/><br/>

        <TrackedButton
          scrollTo="#get-in-touch"
          content="Text us a question"
          gaTrack={gaButton2ContactForm}
        />
        
        <TrackedButton
          url="APPOINTMENT_PATH" target="_blank"
          gaTrack={gaButton2AppointmentClick}
          content="Instant-Book Free Appointment with a Sound Consultant"
        />
  	</div>

	)
}