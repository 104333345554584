import './TrackedButton.scss'

export default function TrackedButton(props) {
	// props.url is the link to re-direct to
	// props.scrollTo is the div id to scroll to within the same page
	// props.target is '_blank' or "self"
	// props.gaTrack is a const function that pings Google Analytics

	return (
		props.url != null ? // go to some other url
			<button> 
				<a 
					href={props.url}
					target={props.target}
					onClick={props.gaTrack}
				> 
					{props.content}
				</a>
			</button>
		: // scroll to something on the same page
			<button>
				<a onClick={() => {
					props.gaTrack();
					window.location.replace(props.scrollTo);
				}}> 
					{props.content}
				</a>
			</button>
	)
}