import 
	{RELATED_POSTS_MAPPING, POST_META }
from "../views/BlogPosts/RELATED_POSTS_MAPPING"

import TrackedLink from './TrackedLink'; 
import {gaRelatedPostClick} from '../libs/trackingLib';
import "./RelatedPosts.scss";

export default function RelatedPosts(props){
	return (
		<div className="Related-Posts">
			<h3>Related Posts</h3>
			{
				RELATED_POSTS_MAPPING[props.post_id].map(
					(post)=>{
						return (
							<TrackedLink
								url={"/blog"+POST_META[post].url}
								target="_self"
								content={POST_META[post].shortTitle}
								gaTrack={gaRelatedPostClick}
							/>
						)
					}
				)
			}
		</div>
	)
}